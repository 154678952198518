import { abpParams } from "./constants";

export let AdBlockPlusStatus = "not_set";

// Sets adblock plus status
export const setAdBlockPlusStatus = async () => {
  if (AdBlockPlusStatus !== "not_set") return;

  // eslint-disable-next-line no-process-env
  const baseUrl = `${process.env.PUBLIC_URL}/px.js`;
  try {
    AdBlockPlusStatus = "block_all";
    await fetch(`${baseUrl}?ch=1&${abpParams}`);
    AdBlockPlusStatus = "acceptable";
    await fetch(`${baseUrl}?ch=2&${abpParams}`); // If enabled, ABP will block this request
    AdBlockPlusStatus = "not_present";
  } catch (e) {
    // do nothing
  }
  return AdBlockPlusStatus;
};
