import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";
import { mergeTerms } from "./configMergeUtils";

export const ConfigContext = createContext({
  landerConfig: {},
  queryConfig: {},
  mergedConfig: {},
  isAdsenseSuccess: null,
  setIsAdsenseSuccess: () => {},
});

export const useLanderConfig = () => {
  return useContext(ConfigContext);
};

export const LanderConfigProvider = ({
  landerConfig,
  queryConfig,
  children = void 0,
}) => {
  const [isAdsenseSuccess, setIsAdsenseSuccess] = useState(true);

  const mergedConfig = {
    keywords: mergeTerms(landerConfig, queryConfig),
  };

  const contextValue = {
    landerConfig: landerConfig,
    queryConfig: queryConfig,
    mergedConfig: mergedConfig,
    isAdsenseSuccess,
    setIsAdsenseSuccess,
  };

  return (
    <ConfigContext.Provider value={ contextValue }>
      {children}
    </ConfigContext.Provider>
  );
};
LanderConfigProvider.propTypes = {
  landerConfig: PropTypes.object.isRequired,
  queryConfig: PropTypes.object.isRequired,
  children: PropTypes.node,
};
