import * as cmpstub from "@iabtechlabtcf/stub";
import { getCookie } from "../commons/HelperFunctions";
import { Settings } from "../config";

// Get query param for overriding country detection. Ex "?forceCMCountry=DE"
export const getTestCountry = () => {
  const params = new URLSearchParams(location.search);
  return params.get("forceCMCountry");
};

// https://ec.europa.eu/eurostat/statistics-explained/index.php?title=Glossary:Country_codes
const TRUST_ARC_ACTIVE_COUNTRIES_ISO = [
  // EU
  "BE",
  "EL",
  "LT",
  "PT",
  "BG",
  "ES",
  "LU",
  "RO",
  "CZ",
  "FR",
  "HU",
  "SI",
  "DK",
  "HR",
  "MT",
  "SK",
  "DE",
  "IT",
  "NL",
  "FI",
  "EE",
  "CY",
  "AT",
  "SE",
  "IE",
  "LV",
  "PL",
  // EEA
  "IS",
  "NO",
  "LI",
  "CH",
  // UK
  "GB",
];

// Enable TrustArc based on this Google Announcement
// https://support.google.com/adsense/answer/14316211?sjid=12681376299848785803-NA
export const getIsConsentRequired = () => {
  if (getTestCountry()) return true;

  const countryCode = getCookie("country");
  return TRUST_ARC_ACTIVE_COUNTRIES_ISO.includes(countryCode);
};

// Injects TrustArc script
export const injectTrustArcScript = (
  cookiePreferencesContainerId,
  privacyPolicylink
) => {
  if (!getIsConsentRequired()) return;

  const scriptClass = "trustarc-script";
  const existingScript =
    document.getElementsByClassName(scriptClass).length > 0;

  if (!existingScript) {
    // Updates global namespace with TCF stubs
    cmpstub();

    // Inject TrustArc script
    const scriptElement = document.createElement("script");
    scriptElement.src =
      `${Settings.TRUSTARC_SCRIPT_URI}?domain=${Settings.TRUSTARC_DOMAIN_ID}&c=${cookiePreferencesContainerId}` +
      `&js=nj&noticeType=bb&text=true` +
      `&privacypolicylink=${encodeURIComponent(privacyPolicylink)}` +
      "&gtm=1";

    const testCountry = getTestCountry();
    if (testCountry) {
      scriptElement.src += `&country=${testCountry}`; // override TrustArc's country detection
    }
    scriptElement.className = scriptClass;
    scriptElement.async = true;
    scriptElement.crossOrigin = "";

    document.body.appendChild(scriptElement);
  }
};
